import React, { useEffect, useState } from "react"
import { RichText } from "prismic-reactjs"
import isEqual from "lodash/isEqual"
import loadable from "@loadable/component"

import {
  isMonthlyProduct,
  linkResolver,
  htmlSerializerUpdateStyle,
  getProduct,
  handleErrorWithPrismic,
  isEmpty,
  ProgressBar,
  getPromotionProducts,
  orderProductsByPrice,
  ROUTES,
  getLocalStorage,
  CURRENT_LANG,
  isBrowser,
} from "@lesmills/gatsby-theme-common"

import {
  PROMOTION_ENTITY,
  SPECIAL_OFFER_SORT_ORDER,
} from "../../constants/promotion"
import { navigate } from "gatsby"

const Subscription = loadable(() => import("@lesmills/gatsby-theme-common"), {
  resolveComponent: components => components.Subscription,
})
const Notification = loadable(() => import("@lesmills/gatsby-theme-common"), {
  resolveComponent: components => components.Notification,
})
const RetailSubscriptions = loadable(() => import("./RetailSubscriptions"))

type Props = {|
  classNames?: {
    wrapper: String,
    subscriptions: String,
    item: String,
  },
  specialOfferClassNames?: {
    specialOfferWrapper: String,
    specialOffer: String,
    specialOfferItem: String,
  },
  prismicData: Object,
  processing?: Boolean,
  layoutData?: Object,
  handleClickStartNow: () => void,
  planNotes?: Array,
  isPromotion?: Boolean,
  lang: String,
  promotionCode: string,
|}

const Subscriptions = ({
  classNames,
  specialOfferClassNames,
  prismicData = {},
  processing,
  layoutData = {},
  handleClickStartNow = () => {},
  planNotes = [],
  isPromotion,
  lang,
  promotionCode,
}: Props) => {
  const [loading, setLoading] = useState(0)
  const [subscriptions, setSubscriptions] = useState([])
  const [error, setError] = useState(null)

  const handleError = err => {
    // Handle error with prismic data
    handleErrorWithPrismic(
      err,
      prismicData.lmod_gpp_err.text,
      setError,
      prismicData
    )
  }

  const setProductList = data => {
    const res = data.data && data.data.promotionPricingPlans

    // LA-1657: res is empty array means invalid promotion code, then navigate to 404
    if (res && res.length === 0) {
      navigate(ROUTES(lang).MEMBERSHIP, {
        state: {
          isPromoExpired: true,
        },
      })

      return
    }

    const promotionProducts = res.map(item => {
      const {
        entity,
        sortOrder,
        productHandle,
        offerHandle,
        trialPrice,
        interval,
        intervalUnit,
        trialDays,
        price,
        finalPrice,
      } = item
      // Format each item to be the same as normal product or offer to display
      // LA-1339: isPromotionOffer = true if entity = O
      const isPromotionOffer = entity === PROMOTION_ENTITY.offer

      return {
        ...item,
        promoCode: promotionCode,
        isPromotionOffer,
        isSpecialOffer: sortOrder === SPECIAL_OFFER_SORT_ORDER, // LA-1339: item is special offer if sortOrder === 0
        product_handle: productHandle,
        offer_handle: offerHandle,
        product_price_point: {
          trial_price_in_cents: trialPrice,
          interval,
          interval_unit: intervalUnit,
          trial_interval: trialDays,
          trial_interval_unit: "day",
          price_in_cents: isPromotionOffer ? finalPrice : price,
        },
      }
    })
    setSubscriptions(promotionProducts)
  }

  useEffect(() => {
    // https://lesmillsinternational.atlassian.net/browse/AB2B-327
    // get promotion list by language code
    const defaultBrowserLang =
      isBrowser &&
      window.navigator &&
      window.navigator.language &&
      window.navigator.language.toLowerCase()
    const savedLang = getLocalStorage(CURRENT_LANG)

    const languageCode = (typeof savedLang === "string"
      ? savedLang
      : defaultBrowserLang
    ).split("-")[0]

    getProduct(setLoading, getPromotionProducts, setProductList, handleError, {
      promotionCode,
      // https://lesmillsinternational.atlassian.net/browse/AB2B-327
      // get promotion list by language code
      languageCode,
    })
  }, [])

  const { promotion_title = [], promotion_start_now_button_label = [] } =
    prismicData || {}
  const promotionBtnLabel = promotion_start_now_button_label.text

  // LA-1339: get monthly subscription to calculate percent off monthly subs, it must be a retail plan
  const monthlySubscription =
    subscriptions.find(
      item => !item.isPromotionOffer && isMonthlyProduct(item)
    ) || {}

  const renderSpecialOffer = () => {
    const specialOffer = subscriptions.find(item => item.isSpecialOffer) || {}

    return !isEmpty(specialOffer) ? (
      <section
        className={"mx-auto " + specialOfferClassNames.specialOfferWrapper}
      >
        <h2 className="text-gray-800 font-primary text-3xl md:text-9xl uppercase leading-short mb-18 md:mb-32 text-center">
          {promotion_title.text}
        </h2>
        <div
          className={"flex flex-wrap " + specialOfferClassNames.specialOffer}
        >
          <Subscription
            subscription={specialOffer}
            classNames={specialOfferClassNames.specialOfferItem}
            prismicData={prismicData}
            disabled={processing}
            layoutData={layoutData}
            handleClickStartNow={handleClickStartNow}
            monthlySubscription={monthlySubscription}
            btnLabel={promotionBtnLabel}
            isPromotion={isPromotion}
          />
        </div>
      </section>
    ) : null
  }

  const renderRetailProducts = () => {
    const retailSubscription = subscriptions.filter(
      item => !item.isSpecialOffer
    )
    const retailSubscriptionByPrice = orderProductsByPrice(
      retailSubscription || []
    )

    return (
      <RetailSubscriptions
        retailSubs={retailSubscriptionByPrice}
        classNames={classNames.item}
        prismicData={prismicData}
        disabled={processing}
        layoutData={layoutData}
        handleClickStartNow={handleClickStartNow}
        monthlySubscription={monthlySubscription}
        btnLabel={promotionBtnLabel}
        isPromotion={isPromotion}
      />
    )
  }

  const renderPromotionSubscriptions = () => {
    if (loading < 100)
      return (
        <div className="mx-auto mb-20 mt-20 md:w-progress-bar w-9/10">
          <ProgressBar
            currentValue={loading}
            classNames={{ wrapper: " w-full" }}
          />
        </div>
      )

    if (error) {
      return (
        <Notification
          message={error.message}
          type="error"
          classNames={{
            wrapper: "justify-center my-20",
          }}
        />
      )
    }

    return (
      <>
        {renderSpecialOffer()}
        <section className={"mx-auto " + classNames.wrapper}>
          <div className={"flex flex-wrap " + classNames.subscriptions}>
            {renderRetailProducts()}
          </div>
          <RichText
            render={planNotes.raw}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializerUpdateStyle(
              "",
              "text-center md:text-base font-base-light text-gray-800 md:leading-tight leading-3normal text-3xs md:pt-5"
            )}
          />
        </section>
      </>
    )
  }

  return renderPromotionSubscriptions()
}

export default React.memo(Subscriptions, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.prismicData, nextProps.prismicData) &&
    isEqual(prevProps.layoutData, nextProps.layoutData)
  )
})
