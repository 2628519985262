import React, { useState } from "react"
import isEqual from "lodash/isEqual"

import type { PromotionPageType } from "../types/PromotionPageType"

import type { UserType, AllLayoutsType } from "@lesmills/gatsby-theme-common"

import FrequentlyAskedQuestionsSection from "../FrequentlyAskedQuestionsSection"
import {
  formatBenefits,
  handleSaveProduct,
  getParamsFromURL,
} from "@lesmills/gatsby-theme-common"

import Hero from "../Hero"
import Subscriptions from "../Subscriptions"
import AllSubscriptionsInclude from "../AllSubscriptionsInclude"
import PartnerPromotion from "./PartnerPromotion"

type Props = {|
  promotionData: PromotionPageType,
  lang: string,
  user: UserType,
  layout?: AllLayoutsType,
  isPartnerPromotion?: Boolean,
  location?: Location,
|}

const Promotion = ({
  promotionData,
  lang,
  user = {},
  layout = {},
  isPartnerPromotion,
  location,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const {
    hero_description = {},
    hero_image = {},
    hero_title = {},
    body = [],
    plan_notes = {},
  } = promotionData || {}

  const { les_mills_plus_logo } = layout || {}

  const [all_subscriptions = {}, faqs = {}] = body
  const benefits = all_subscriptions.items || []
  const questions = faqs.items || []

  const promotionCode = getParamsFromURL("code")

  return (
    <>
      <Hero
        heroImg={hero_image}
        description={hero_description}
        title={hero_title.text}
        lang={lang}
        logo={les_mills_plus_logo}
      />
      {isPartnerPromotion ? (
        <PartnerPromotion
          prismicData={promotionData}
          layoutData={layout}
          user={user}
          lang={lang}
          location={location}
        />
      ) : (
        <Subscriptions
          lang={lang}
          specialOfferClassNames={{
            specialOfferWrapper:
              "lg:max-w-1233 md:max-w-750 md:pt-65 md:pb-38 pt-40",
            specialOffer:
              "justify-center subscription-promotion border-2 max-w-254 pb-32 md:pb-0 md:max-w-920 m-auto border-orange-400",
            specialOfferItem:
              "w-9/10 pb-0 pt-25 md:w-50/100 md:ml-12 md:mr-12 md:mb-45",
          }}
          classNames={{
            wrapper: "lg:max-w-1233 md:max-w-750 md:pt-80 md:pb-23 py-45",
            subscriptions: "justify-center subscription-promotion",
            item: "w-9/10 md:w-30/100 md:ml-12 md:mr-12 md:mb-35 mb-60",
          }}
          prismicData={promotionData}
          processing={loading}
          layoutData={layout}
          handleClickStartNow={product => {
            product = {
              ...product,
            }
            handleSaveProduct(user, product, setLoading, lang) // LA-1339: if promotion offer, isAffiliate is true
          }}
          planNotes={plan_notes}
          isPromotion
          promotionCode={promotionCode}
        />
      )}
      <AllSubscriptionsInclude
        benefits={formatBenefits(benefits)}
        title={
          all_subscriptions.primary &&
          all_subscriptions.primary.all_subscriptions_title.text
        }
      />
      <FrequentlyAskedQuestionsSection
        questions={questions}
        title={faqs.primary && faqs.primary.faq_title.text}
      />
    </>
  )
}

export default React.memo(Promotion, (prevProps, nextProps) => {
  return isEqual(prevProps.promotionData, nextProps.promotionData)
})
