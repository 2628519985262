import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import type { PromotionPageType } from "../types/PromotionPageType"
import type { UserType } from "@lesmills/gatsby-theme-common"

import Promotion from "../components/Promotion"

import {
  renderSEO,
  Layout,
  PublicRoute,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  pageContext: {
    location: Object,
    lang: string,
  },
  data: PromotionPageType,
|}

const PartnerPromotionPage = (props: Props) => {
  const { pageContext, location, data } = props || {}
  const scriptsPromotionPage = [DATA_LAYER_SCRIPT]

  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  const { prismicLayout = {}, prismicPartnerPromotionPage = {} } = data

  const promotionData = prismicPartnerPromotionPage.data || {}
  const layout = prismicLayout.data || {}

  const renderPromotionPage = ({ user, checkingSession }: UserType) => (
    <Layout
      data={layout}
      headerless
      lang={lang}
      user={user}
      isLoading={checkingSession}
    >
      {renderSEO(promotionData, location.pathname, lang, scriptsPromotionPage)}
      <Promotion
        user={user}
        promotionData={promotionData}
        layout={layout}
        lang={lang}
        isPartnerPromotion
        location={location}
      />
    </Layout>
  )

  return <PublicRoute component={renderPromotionPage} {...props} lang={lang} />
}

export const query = graphql`
  query partnerPromotionQuery($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        ...LayoutUnits
        best_value {
          raw
          text
        }
        body1 {
          ...LayoutBody1Navigation
        }
        sign_in_button {
          raw
          text
        }
        les_mills_plus_logo {
          alt
          url
        }
      }
    }
    prismicPartnerPromotionPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicPartnerPromotionPageBodyAllSubscriptions {
            id
            items {
              subscription_description {
                raw
                text
              }
              subscription_icon {
                alt
                url
              }
            }
            primary {
              all_subscriptions_title {
                raw
                text
              }
            }
          }
          ... on PrismicPartnerPromotionPageBodyFaqs {
            id
            items {
              answer {
                raw
                text
              }
              question {
                raw
                text
              }
            }
            primary {
              faq_title {
                raw
                text
              }
            }
          }
        }
        from_second_payment {
          raw
          text
        }
        hero_description {
          raw
          text
        }
        hero_image {
          alt
          url
        }
        hero_title {
          raw
          text
        }
        hero_video {
          url
        }
        lmod_gpp_err {
          text
        }
        lmod_lpbc_er {
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        plan_notes {
          raw
          text
        }
        promotion_after_trial {
          raw
          text
        }
        promotion_after_your_trial {
          raw
          text
        }
        promotion_billed_price {
          raw
          text
        }
        promotion_cancel_future_any_time {
          raw
          text
        }
        promotion_customers_only {
          raw
          text
        }
        promotion_free_trial {
          raw
          text
        }
        promotion_offer_available {
          raw
          text
        }
        promotion_percent_off_monthly_price {
          raw
          text
        }
        promotion_start_now_button_label {
          raw
          text
        }
        promotion_title {
          raw
          text
        }
        promotion_unit {
          raw
          text
        }
      }
    }
  }
`

export default withPreview(PartnerPromotionPage)
