const nameMapping = {
  timestamp: "timestamp",
  expirydays: "expiryDays",
  guid: "guid",
  signature: "signature",
  code: "promotionCode",
}

/**
 * This function parse URL search string and unpack it into a js object.
 *
 * It returns js object only when following conditions satisfy
 *   * It is a valid URL search string
 *   * All required fields are in the search string
 *
 * @param {string} search URL search string
 * @returns js object or null
 */
export const unpackSignedLink = location => {
  const params = new URLSearchParams(location.search)

  const result = {
    hostName: location.hostname,
    path: location.pathname,
  }

  for (const key of params.keys()) {
    if (nameMapping[key]) {
      result[nameMapping[key]] = decodeURIComponent(params.get(key))
    }
  }

  const { timestamp, guid, signature, promotionCode } = result

  // check the mandatory fields
  if (!timestamp || !guid || !signature || !promotionCode) {
    return null
  }

  return result
}
