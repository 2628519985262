import React, { useState, useEffect } from "react"
import {
  ROUTES,
  Spinner,
  handleSaveProduct,
  Subscription,
  publicAPI,
  getPromotionProducts,
  Notification,
  handleErrorWithPrismic,
  validateSignedLinkPromotion,
  PROMOTION_SIGNED_LINK_ERRORS,
} from "@lesmills/gatsby-theme-common"
import { unpackSignedLink } from "../../utils/utilities"
import { navigate } from "gatsby"

type Props = {|
  prismicData: object,
  layoutData: object,
  user: object,
  lang: string,
  location: Location,
|}

const PartnerPromotion = ({
  prismicData = {},
  layoutData = {},
  user = {},
  lang,
  location = {},
}: Props) => {
  const [promotion, setPromotion] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const signedLink = unpackSignedLink(location)

  const {
    promotion_title = {},
    promotion_start_now_button_label = {},
  } = prismicData

  useEffect(() => {
    if (signedLink) {
      publicAPI(
        validateSignedLinkPromotion,
        ({ data }) => {
          const res = data.data && data.data.validateSignedLinkPromotion
          if (res.valid) {
            // get promotion with valid url
            publicAPI(
              getPromotionProducts,
              setProductList,
              err => {
                setLoading(false)
                handleErrorWithPrismic(
                  err,
                  prismicData.lmod_gpp_err.text,
                  setError,
                  prismicData
                )
              },
              { promotionCode: signedLink.promotionCode }
            )
            return
          }

          //AB2B-594: Navigate to membership and so error message if API returns error
          navigate(ROUTES(lang).MEMBERSHIP, {
            state: {
              errorCode:
                PROMOTION_SIGNED_LINK_ERRORS[res.error] ||
                PROMOTION_SIGNED_LINK_ERRORS.SIGNED_LINK_INVALID,
            },
          })
        },
        //AB2B-594: Navigate to membership and so invalid if have appsync error
        () => {
          navigate(ROUTES(lang).MEMBERSHIP, {
            state: {
              isPromoExpired: true,
            },
          })
        },
        {
          signedLink,
        }
      )
    } else {
      //AB2B-594: Navigate to membership and so invalid error if missing required params in url
      navigate(ROUTES(lang).MEMBERSHIP, {
        state: {
          isPromoExpired: true,
        },
      })
    }
  }, [])

  const setProductList = ({ data }) => {
    setLoading(false)
    const res = data.data && data.data.promotionPricingPlans

    let product = res[0]
    const {
      productHandle,
      trialPrice,
      interval,
      intervalUnit,
      trialDays,
      price,
      name,
      description,
      currency,
    } = product

    product = {
      name,
      description,
      currency,
      promoCode: signedLink.promotionCode,
      isSpecialOffer: true,
      product_handle: productHandle,
      product_price_point: {
        trial_price_in_cents: trialPrice,
        interval,
        interval_unit: intervalUnit,
        trial_interval: trialDays,
        trial_interval_unit: "day",
        price_in_cents: price,
      },
      isPromotionOffer: true,
    }

    setPromotion(product)
  }

  const renderPromotion = () => {
    if (loading) {
      return (
        <div className="w-full my-30 relative py-30">
          <Spinner />
        </div>
      )
    }

    if (error) {
      return (
        <Notification
          message={error.message}
          type="error"
          classNames={{
            wrapper: "justify-center my-20",
          }}
        />
      )
    }

    return (
      <section
        className={"mx-auto lg:max-w-1233 md:max-w-750 md:pt-65 md:pb-38 pt-40"}
      >
        <h2 className="text-gray-800 font-primary text-3xl md:text-9xl uppercase leading-short mb-18 md:mb-32 text-center">
          {promotion_title.text}
        </h2>
        <div
          className={
            "flex flex-wrap justify-center subscription-promotion border-2 max-w-254 pb-32 md:pb-0 md:max-w-920 m-auto border-orange-400"
          }
        >
          <Subscription
            subscription={promotion}
            classNames="w-9/10 pb-0 pt-25 md:w-50/100 md:ml-12 md:mr-12 md:mb-45"
            prismicData={prismicData}
            layoutData={layoutData}
            handleClickStartNow={product => {
              product = {
                ...product,
                signedLink,
              }

              // AB2B-521: isAffiliate is false for partner promotion
              handleSaveProduct(user, product, setLoading, lang, false, true)
            }}
            btnLabel={promotion_start_now_button_label.text}
            isPromotion
            isPartnerPromotion
          />
        </div>
      </section>
    )
  }

  return <>{renderPromotion()}</>
}

export default PartnerPromotion
